.rightClick {
    z-index: 12;
    position: fixed;
    background-color: white;
    width: 150;
    padding:5px;
    /* border: 1px solid #ccc */
  }
  .menuElement {
    cursor: pointer;
    padding: 10px 10px;
    width: 120;
    background-color: inherit;
  }
  .menuElement:hover {
    color: #0f111e;
    background: rgb(126, 128, 133);
  }
.contextMenu {
    position: fixed;
    background: white;
    color: black;
    box-shadow: 0px 2px 10px #999999;
    
    &--option {
        padding: 6px 50px 5px 10px;
        min-width: 160px;
        cursor: pointer;
        font-size: 12px;
        &:hover {
            background: linear-gradient(to top, #555, #333);
            color: white;
            cursor: pointer;
        }
        
        &:active {
            color: #e9e9e9;
            background: linear-gradient(to top, #555, #444);
        }
        
        &__disabled {
            color: #999999;
            pointer-events: none;
        }
    }
    
    &--separator {
        width: 100%;
        height: 1px;
        background: #CCCCCC;
        margin: 0 0 0 0;
    }
}

.MuiPaper-root {
    max-width: inherit !important;
    max-height: inherit !important;
    width: 62%;
    height: inherit;
    display: block;
  }
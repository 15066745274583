.schedule_history {
    color: #ed265d;
    font-size: 24px;
    margin-left: 20px;
}

#name.form-control, .select2-search__field, .form-field, .period, .dataTables_length select,
#playlists_filter label input,
#crons_filter label input {
    border: 1px solid #383B4C !important;
    border-radius: 25px !important;
    color: rgb(126, 128, 133) !important;
    background: #0f111e !important;
}

.hr {
    height: 2px !important;
    background: white;
    display: block;
    border: 0px !important;
    background-color: white;
}
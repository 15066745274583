@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.NavbarContainer {
  /* max-width: 1100px; */
  /* margin: 0; */
  width: 100%;
  float: right;
  right: 0;
  padding: 0 15px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  height: 100%;
}

.navbar {
  height: 80px;
  background-color: #0f111e;
  position: relative;
}

.logo {
}

.menu-icon {
  display: none;
}

.nav-elements {
  margin-left: auto;
  float: right;
  margin-right: 4%;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-top: 20px
}

.nav-elements ul li {
  margin-right: 20px;
  border: 2px solid #ed265d;
  padding: 8px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: white;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    color: white;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #0c0d14;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .nav-elements-ul {
    text-align: center;
  }

  .nav-elements-ul li {
    border: 2px solid #ed265d;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  .schedule:hover {
    cursor: pointer;
  }
}
.react-datepicker__input-container input {
  background-color: #333;
}

.react-datepicker__day--selected {
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
}

.react-datepicker-wrapper{
  width: 100%
}
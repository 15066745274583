body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  display: flex;
  align-items: center;
  margin: 3rem 0;
  flex-direction: column;
}

table {
  border-spacing: 0;
  text-align: center;
}

tr:last-child > td {
  border-bottom: 0;
  border-bottom: 1px solid black;
}

th
td {
  margin: 0;
  padding: 0.5rem;
  border-right: 1px solid black;
}
th:last-child ,
td:last-child {
  border-right: 0;
}

tr {
  border-bottom: 1px solid black;
  margin-bottom: 3px;
}
.styled-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .styled-list li {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }